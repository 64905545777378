export interface NotificationInternalParams {
  type: string;
  title?: string;
  message: string;
  timeout?: number;
  onTimeout?: () => void;
}

export interface NotificationExternalParams {
  title?: string;
  message: string;
}

export const LANG = 'lang';

export const authTypes = {
  AUTH_STEP: 'auth_step',
  AUTH_USER_CHANGED: 'auth_userchanged',
  AUTH_USER_CLAIMS_CHANGED: 'auth_userclaimschanged',
  AUTH_SESSION_CHANGED: 'auth_sessionchanged',
  AUTH_ACCOUNT_CLAIMS_CHANGED: 'auth_accountclaimschanged',
  AUTH_USER_ACCOUNTS_CHANGED: 'auth_useraccountschanged',
  AUTH_ACCOUNT_ID_CHANGED: 'auth_accountidchanged'
};

export const uiTypes = {
  UI_THEME_CHANGED: 'ui_themechanged',
  UI_SET_NOTIFICATION: 'ui_set_notification',
  UI_UNSET_NOTIFICATION: 'ui_unset_notification',
  UI_FOCUS_QUICKBUY: 'ui_focus_quickbuy'
};
