import { get } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupDepositsListener({ baseUrl, requestsInFlight }) {
  async function fetchDeposits({ asset, accountId }) {
    const account = await context.account();
    if (account.id !== accountId) return null;
    return get(`${baseUrl}/accounts/${accountId}/assets/${asset}/deposits`, account);
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.accountId) return false;
    if (!message.params?.asset) return false;
    return true;
  }

  const generateFetchKey = ({ asset = undefined, accountId = undefined } = {}) => {
    if (asset && accountId) return `deposits-${asset}-${accountId}`;
    if (accountId) return `deposits-${accountId}`;
    return 'deposits';
  };

  return setupFetchListener({
    fetchContent: fetchDeposits,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupDepositsListener;
