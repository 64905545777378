import { openDB } from 'idb';

// Keyval store https://github.com/jakearchibald/idb#keyval-store
const objectStoreName = 'keyval';

const dbPromise = dbName =>
  openDB(dbName, 1, {
    upgrade(db) {
      db.createObjectStore(objectStoreName);
    },
    terminated() {
      console.warn(`IDB ${dbName} was terminated.`);
    }
  });

const idb = dbName => ({
  async get(key) {
    return (await dbPromise(dbName)).get(objectStoreName, key);
  },
  async set(key, val) {
    return (await dbPromise(dbName)).put(objectStoreName, val, key);
  },
  async delete(key) {
    return (await dbPromise(dbName)).delete(objectStoreName, key);
  },
  async clear() {
    return (await dbPromise(dbName)).clear(objectStoreName);
  },
  async keys() {
    return (await dbPromise(dbName)).getAllKeys(objectStoreName);
  }
});

export const publicStore = idb('nbx-public');
export const privateStore = idb('nbx-private');

window.IDB_PUBLIC_STORE = publicStore;
window.IDB_PRIVATE_STORE = privateStore;
