import humps from 'humps';

export interface RequestOptions {
  decamelizeRequestKeys?: boolean;
  camelizeResponseKeys?: boolean;
  returnHeaders?: boolean;
}

export const parseResponseBody = async (
  response: Response,
  accept = 'string',
  options?: RequestOptions
): Promise<string | Record<string, unknown>> => {
  if (!response.status) return `${response}`;
  if (response.status === 204) return {};
  const camelizeResponseKeys = options?.camelizeResponseKeys ?? true;

  let body: Record<string, unknown> = {};
  if (accept === 'application/json') {
    try {
      const parsed = await response.json();
      if (parsed) body = camelizeResponseKeys ? humps.camelizeKeys(parsed) : parsed;
    } catch (caughtError) {
      console.warn('Failed to parse response JSON body: ', caughtError);
      body = {};
    }
  } else if (accept === 'text/plain') {
    return response.text();
  } else {
    return new Promise(resolve => {
      console.error(`parseResponseBody was called with invalid mediaType: ${accept}`);
      resolve({});
    });
  }

  if (options?.returnHeaders) {
    const headers = {};
    [...response.headers.entries()].forEach(([headerKey, headerVal]) => {
      headers[headerKey] = headerVal;
    });
    return {
      headers,
      body
    };
  }
  return body;
};
