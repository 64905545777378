import { get } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupPreferencesListener({ baseUrl, requestsInFlight }) {
  async function fetchPreferences() {
    const user = await context.user();
    return get(`${baseUrl}/users/${user.id}/preferences`, user);
  }

  const isMessageValid = () => true;

  const generateFetchKey = () => {
    return 'preferences';
  };

  return setupFetchListener({
    fetchContent: fetchPreferences,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupPreferencesListener;
