export const retryPromise = async (callback, maxAttempts = 5) => {
  let attempts = 0;
  let catchErr;

  while (attempts < maxAttempts) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await callback();
    } catch (err) {
      const retryDelay = 2 ** attempts * 1000;
      console.debug(`Retrying promise. Delaying ${retryDelay} ms`); // eslint-disable-line
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise(r => setTimeout(r, retryDelay));
      attempts += 1;
      catchErr = err;
    }
  }

  throw catchErr;
};
