// eslint-disable-next-line import/no-cycle
import { Context } from '../auth';
import {
  createRequest,
  Payload,
  RequestOptions,
  sendPagedRequest,
  sendRequest,
  sendRequestExternal
} from './request';

export const get = (
  url: string,
  context?: Context,
  accept?: string,
  options?: RequestOptions
): Promise<unknown> => sendRequest(createRequest('GET', url, {}, accept, options), context, accept, options);

export const getWithCustomHeaders = (
  url: string,
  headers: Record<string, string>,
  context?: Context,
  accept?: string,
  options?: RequestOptions
): Promise<unknown> =>
  sendRequest(createRequest('GET', url, { headers }, accept, options), context, accept, options);

export const getExternal = (
  url: string,
  accept?: string,
  options?: RequestOptions
): Promise<string | Record<string, unknown>> =>
  sendRequestExternal(createRequest('GET', url, {}, accept, options), accept, options);

export const getPaged = (
  url: string,
  context?: Context,
  pagingState?: string,
  options?: RequestOptions
): Promise<unknown> => {
  const payload = pagingState ? { headers: { 'x-paging-state': pagingState } } : {};
  const accept = 'application/json';
  return sendPagedRequest(createRequest('GET', url, payload, accept, options), context, accept, options);
};

export const getPagedByUrl = (url: string, context?: Context, options?: RequestOptions): Promise<unknown> => {
  const accept = 'application/json';
  return sendPagedRequest(createRequest('GET', url, {}, accept, options), context, accept, options);
};

export const post = (
  url: string,
  payload?: Payload,
  context?: Context,
  options?: RequestOptions
): Promise<unknown> => {
  const accept = options?.accept || 'application/json';
  return sendRequest(createRequest('POST', url, payload, accept, options), context, accept, options);
};

export const del = (
  url: string,
  payload?: Payload,
  context?: Context,
  options?: RequestOptions
): Promise<unknown> => {
  const accept = options?.accept || 'application/json';
  return sendRequest(createRequest('DELETE', url, payload, accept, options), context, accept, options);
};

export const put = (
  url: string,
  payload?: Payload,
  context?: Context,
  options?: RequestOptions
): Promise<unknown> => {
  const accept = options?.accept || 'application/json';
  return sendRequest(createRequest('PUT', url, payload, accept, options), context, accept, options);
};

export const patch = (
  url: string,
  payload?: Payload,
  context?: Context,
  options?: RequestOptions
): Promise<unknown> => {
  const accept = options?.accept || 'application/json';
  return sendRequest(createRequest('PATCH', url, payload, accept, options), context, accept, options);
};
