// eslint-disable-next-line import/no-cycle
import { refreshAccountToken } from './accountToken';
import { refreshSession } from './session';
import { Session } from '../types';
import { refreshUserToken } from './userToken';

export async function refreshTokens(
  session: Session,
  accountId: string,
  deviceTokenFromBiometrics: string | null | undefined = null
): Promise<[any, any, any]> {
  const newSession = await refreshSession(session, deviceTokenFromBiometrics);
  if (!newSession) return [null, null, null];
  return Promise.all([newSession, refreshAccountToken(newSession, accountId), refreshUserToken(newSession)]);
}
