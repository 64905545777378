import { get } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';

let baseUrl = 'assets';

const NBXAssets = {
  init: config => ({ baseUrl } = config),

  getAssets: async accountToken => {
    let accountContext;
    if (!accountToken) {
      try {
        accountContext = await context.account();
      } catch (error) {
        console.warn('failed to get account context, fetching public assets');
      }
    } else {
      accountContext = { token: accountToken };
    }
    return get(`${baseUrl}/`, accountContext);
  }
};

export default NBXAssets;
