import { NBXStorage } from '@nbx/frontend-helpers/storage';
import { isNullish } from 'helpers/objects';
import { publicStore, privateStore } from './stores';
import purgeCache from './purgeCache';

class MessageFormatError extends Error {
  constructor(message = 'Malformed fetch message', ...args) {
    super(message, ...args);
  }
}

async function checkForCachedResult({ fetchKey, params, timeInterval, requestTime, store }) {
  if (!window.CAN_CACHE_IDB) return null;
  // historical trade history (beyond the first page) never changes
  // so we always pull from the cache, regardless of how old it is
  const isHistoricTrades = !!params.nextPageUrl && fetchKey.includes('trades-');
  let results = null;
  if (isHistoricTrades || !isNullish(timeInterval)) {
    const cacheValue = await store.get(fetchKey);
    if (cacheValue) {
      const { data, time } = cacheValue;
      // we're a little fuzzy so that if the data is within 1% of the boundary, we fetch instead of caching
      // catches the case where tiny variances in timing can cause too many cache-hits
      // e.g. if timeInterval is 2500 and the request is made every 2500 ms, millisecond-scale variances in timing
      // can result in showing stale data for another 2500ms
      if (isHistoricTrades || timeInterval - (requestTime - time) > timeInterval * 0.01) {
        results = data;
      }
    }
  }
  return results;
}

async function cacheNewResult({ fetchKey, store, requestTime, data }) {
  if (!window.CAN_CACHE_IDB) return;
  try {
    await store.set(fetchKey, { data, time: requestTime });
  } catch (caughtError) {
    console.error(caughtError);
    if (caughtError.name === 'QuotaExceededError') {
      console.warn('Exceeded indexedDB storage quota, purging old values...');
      purgeCache();
    } else {
      console.error(`Failed to cache result for ${fetchKey}:`, caughtError);
    }
  }
}

export function setupFetchListener({
  fetchContent,
  isMessageValid,
  generateFetchKey,
  requestsInFlight,
  isPrivate = false
}) {
  const store = isPrivate ? privateStore : publicStore;
  let tornDown = false;

  async function handleFetchOrCache(params, timeInterval) {
    const fetchKey = generateFetchKey(params);
    if (requestsInFlight[fetchKey]) return null;
    requestsInFlight[fetchKey] = true;
    let results;
    try {
      const requestTime = new Date().getTime();
      results = await checkForCachedResult({ fetchKey, params, timeInterval, store, requestTime });
      if (!results) {
        results = await fetchContent(params, timeInterval);
        if (!tornDown) cacheNewResult({ fetchKey, store, requestTime, data: results });
      }
    } finally {
      requestsInFlight[fetchKey] = false;
    }
    return results;
  }

  async function handleSubscription(message) {
    let params;
    try {
      params = message.params;
      if (!isMessageValid(message)) {
        throw new MessageFormatError(
          `Malformed fetch message: ${generateFetchKey()}, ${JSON.stringify(message)}`
        );
      }
      const { timeInterval } = message;
      const results = await handleFetchOrCache(params, timeInterval);
      const activeAccount = await NBXStorage.getItem('active-account');
      const accountsMatch = params.accountId !== undefined ? params.accountId === activeAccount : true;
      if (results !== null && !tornDown && accountsMatch)
        window.PUBLISH(`fetch_result_${generateFetchKey(params)}`, results);
    } catch (error) {
      const key = generateFetchKey(params);
      console.error('handleSubscription error', key, error); // eslint-disable-line
      window.PUBLISH(`fetch_error_${key}`, error);
    }
  }

  window.SUBSCRIBE(`fetch_get_${generateFetchKey()}`, handleSubscription);

  return () => {
    tornDown = true;
    window.UNSUBSCRIBE(`fetch_get_${generateFetchKey()}`, handleSubscription);
  };
}

async function waitForDupeToResolve(fetchKey, baseFetchKey) {
  return new Promise((resolve, reject) => {
    const resolveFunction = result => {
      window.UNSUBSCRIBE(`fetch_result_${fetchKey}`, resolveFunction);
      window.UNSUBSCRIBE(`fetch_error_${baseFetchKey}`, rejectFunction);
      resolve(result);
    };
    const rejectFunction = error => {
      window.UNSUBSCRIBE(`fetch_result_${fetchKey}`, resolveFunction);
      window.UNSUBSCRIBE(`fetch_error_${baseFetchKey}`, rejectFunction);
      reject(error);
    };

    window.SUBSCRIBE(`fetch_result_${fetchKey}`, resolveFunction);
    window.SUBSCRIBE(`fetch_error_${baseFetchKey}`, rejectFunction);
  });
}

export async function fetchDataOutsideListener({
  params = {},
  generateFetchKey,
  fetchContent,
  store,
  requestsInFlight,
  timeInterval
}) {
  const fetchKey = generateFetchKey(params);
  if (requestsInFlight[fetchKey]) {
    // If duplicate request is already in progress, wait for it to resolve and grab the result
    return waitForDupeToResolve(fetchKey, generateFetchKey());
  }
  requestsInFlight[fetchKey] = true;
  let results;
  try {
    const requestTime = new Date().getTime();
    results = await checkForCachedResult({ fetchKey, params, timeInterval, store, requestTime });
    if (!results) {
      results = await fetchContent(params, timeInterval);
      cacheNewResult({ fetchKey, store, requestTime, data: results });
    }
  } finally {
    requestsInFlight[fetchKey] = false;
  }
  return results;
}
