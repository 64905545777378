import { get } from '@nbx/frontend-helpers/fetch-api';
import { SessionTokenError, context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupOrderBookListener({ baseUrl, requestsInFlight }) {
  async function fetchOrderBook({ marketId, rawData }) {
    let account;
    try {
      account = await context.account();
    } catch (error) {
      if (!(error instanceof SessionTokenError)) throw error;
    }
    const results = await get(`${baseUrl}/${marketId}/orders`, account);
    if (rawData) return results;
    const { default: processOrderBook } = await import('./orderBookAsync');
    return processOrderBook(results);
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.marketId) return false;
    return true;
  }

  function generateFetchKey({ marketId, rawData } = {}) {
    if (marketId && rawData) return `orderBook-rawData-${marketId}`;
    if (marketId) return `orderBook-${marketId}`;
    return `orderBook`;
  }

  return setupFetchListener({
    fetchContent: fetchOrderBook,
    isMessageValid,
    generateFetchKey,
    requestsInFlight
  });
}

export default setupOrderBookListener;
