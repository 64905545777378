/* eslint-disable max-classes-per-file */
export class ConfigurationError extends Error {
  constructor(message = 'Configuration error', ...args) {
    super(message, ...args);
  }
}

export class MicrofrontendMountError extends Error {
  constructor(message = 'Microfrontend Mount Error', ...args) {
    super(message, ...args);
  }
}
