import { getPaged, getPagedByUrl } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupAccountOrdersListener({ baseUrl, requestsInFlight }) {
  async function fetchFunds({ accountId, nextPageUrl }) {
    const account = await context.account();
    if (account.id !== accountId || (nextPageUrl && !nextPageUrl.includes(account.id))) return null;
    if (nextPageUrl) {
      return getPagedByUrl(nextPageUrl, account);
    }
    return getPaged(`${baseUrl}/${accountId}/orders`, account);
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.accountId) return false;
    return true;
  }

  function generateFetchKey({ accountId, nextPageUrl } = {}) {
    if (nextPageUrl && accountId) return `accountOrders-${accountId}-${nextPageUrl}`;
    if (accountId) return `accountOrders-${accountId}`;
    return `accountOrders`;
  }

  return setupFetchListener({
    fetchContent: fetchFunds,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupAccountOrdersListener;
