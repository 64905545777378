import { getPaged, getPagedByUrl } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupOpenOrdersByMarketListener({ baseUrl, requestsInFlight }) {
  async function fetchFunds({ accountId, marketId, nextPageUrl }) {
    const account = await context.account();
    if (account.id !== accountId || (nextPageUrl && !nextPageUrl.includes(account.id))) return null;
    if (nextPageUrl) {
      return getPagedByUrl(nextPageUrl, account);
    }
    return getPaged(`${baseUrl}/${accountId}/markets/${marketId}/orders`, account);
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.accountId) return false;
    if (!message.params?.marketId) return false;
    return true;
  }

  function generateFetchKey({ accountId, marketId, nextPageUrl } = {}) {
    if (nextPageUrl && accountId && marketId) return `openOrders-${marketId}-${accountId}-${nextPageUrl}`;
    if (accountId && marketId) return `openOrders-${marketId}-${accountId}`;
    return `openOrders`;
  }

  return setupFetchListener({
    fetchContent: fetchFunds,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupOpenOrdersByMarketListener;
