import { getWithCustomHeaders } from '@nbx/frontend-helpers/fetch-api';

let baseUrl = 'users';

const NBXUsers = {
  init: config => ({ baseUrl } = config),
  vippsUserInfo: async vippsAccessToken => {
    const headers = { 'x-vipps-access-token': vippsAccessToken };
    return getWithCustomHeaders(`${baseUrl}/vipps_userinfo`, headers);
  }
};

export default NBXUsers;
