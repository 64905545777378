import './iphonecheck';
// import * as CardanoWasm from '@dcspark/cardano-multiplatform-lib-browser';
import detectFeaturePreview, { loadFeatureBranch } from './detectPreviewFeature';
import startNBX from './bootstrap';
import { setupDatadogWebApp } from './datadog';
import { patchWebFetch } from './patchWebFetch';

setupDatadogWebApp();

window.fetch = patchWebFetch(window.fetch);

navigator.sendBeacon = null;

detectFeaturePreview().then(previewing => {
  if (!previewing) startNBX('web');
  else loadFeatureBranch();
});
