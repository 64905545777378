import unfetch from 'unfetch';

/* Polyfill fetch
We shouldn't need unfetch but for some reason our app breaks if we don't use it.
Firebase installation requests doesn't work with unfetch
*/

export const patchWebFetch = nativeFetch => (url, options) =>
  url.startsWith('https://firebaseinstallations.googleapis.com/v1/projects/')
    ? nativeFetch(url, options)
    : unfetch(url, options);
