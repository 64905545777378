import { get } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';

let baseUrl = 'markets';

const NBXMarkets = {
  init: config => ({ baseUrl } = config),

  getMarkets: async ({ disabled = false, accountToken } = {}) => {
    let accountContext;
    if (!accountToken) {
      try {
        accountContext = await context.account();
        // eslint-disable-next-line
      } catch (ignore) {}
    } else {
      accountContext = { token: accountToken };
    }
    return get(`${baseUrl}${disabled ? '' : `?disabled=false/`}`, accountContext);
  }
};

export default NBXMarkets;
