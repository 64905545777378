import humps from 'humps';
import { NBXMarkets, NBXAssets } from 'services';
import { NBXStorage } from '@nbx/frontend-helpers/storage';

let marketsConfig = {};

export function setMarketsConfig(marketsConfigData) {
  marketsConfig = marketsConfigData;
}

export async function fetchAndFormatMarkets({ disabled = false } = {}) {
  const marketsInfo = await NBXMarkets.getMarkets({ disabled });
  const accountClaims = JSON.parse(await NBXStorage.getItem('account-claims'));
  const marketsJWT = accountClaims?.markets;

  const formattedMarkets = marketsInfo.map(market => {
    const marketJWT = humps.camelizeKeys(marketsJWT?.[market.id]);
    return {
      ...market,
      pair: market.id,
      // Translate to old data format for compat
      baseCurrency: market.baseAsset,
      quoteCurrency: market.quoteAsset,
      minTick: Number.parseFloat(market.quoteIncrement),
      minQty: Number.parseFloat(market.baseIncrement),
      minTickLength: `${market.quoteIncrement}`.split('.')[1].length,
      minQtyLength: `${market.baseIncrement}`.split('.')[1].length,
      // Override trading fees with value from JWT
      makerFee: marketJWT?.makerFee ?? marketsConfig.previewTradingFee,
      takerFee: marketJWT?.takerFee ?? marketsConfig.previewTradingTakerFee
    };
  });
  return formattedMarkets;
}

const getAddressFormat = (type, assetid) => {
  if (type.includes('BTC')) return 'BTC';
  if (type.includes('ADA')) return 'ADA';
  if (type.includes('CARDANO-CNT')) return 'ADA';
  if (type.includes('ETH-ERC20')) return 'ETH';
  return assetid;
};

// eslint-disable-next-line default-param-last
export async function fetchAndFormatAssets(assetsFromJWT = {}, accountToken) {
  const assetsInfo = await NBXAssets.getAssets(accountToken);
  const formattedAssets = {};
  assetsInfo.forEach(asset => {
    formattedAssets[asset.id] = {
      ...asset,
      // Translate to old data format for compat
      fiat: asset.type === 'FIAT',
      pegged: asset.id === 'USDC',
      addressFormat: getAddressFormat(asset.type, asset.id),
      minWithdrawalQuantity: asset.withdrawalAmountMinimum,
      feePrecision: asset.decimalPrecision,
      maxOrderPrecision: asset.decimalPrecision,
      maxWithdrawalPrecision: asset.decimalPrecision,
      displayPrecision: asset.decimalPrecisionDisplay,
      // Override withdrawal fee with value from JWT
      ...humps.camelizeKeys(assetsFromJWT[asset.id])
    };
  });
  return formattedAssets;
}
