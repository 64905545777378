import { NBXStorage } from '@nbx/frontend-helpers/storage';
import setupMarketsListener from './markets';
import setupAssetsListener from './assets';
import setupOrderBookListener from './orderBook';
import setupFundsListener from './funds';
import setupTradesListener from './trades';
import setupTickersListener from './tickers';
import setupAccountOrdersListener from './accountOrders';
import setupOpenOrdersByMarketListener from './openOrders';
import setupReferralReportListener from './referrals';
import setupPreferencesListener from './preferences';
import setupDepositsListener from './deposits';
import setupWithdrawalsListener from './withdrawals';
import purgeCache, { oneMonthAsMS } from './purgeCache';

// event-driven data fetching interface, usable by all microfrontends
// all listener functions have two args
// params - an object containing params to pass into the fetch function
// timeInterval - data older than this interval is invalid and will be re-fetched, otherwise use cached result
// If no timeInterval is specified, will always get fresh data
// Any microfrontend can subscribe and listen to a data channel
// e.g. trades from BTC-NOK can be retrieved by subscribing to `fetch_result_trade-BTC-NOK`
// a microfrontend can request new data by publishing to a channel e.g. `fetch_get_trade-BTC-NOK`
// all subscribed microfrontends will then get the new data.
// errors would be published to `fetch_error_trade-BTC-NOK`

// Index of requests-in-progress. Listeners can check against this this prevent themselves from duplicating requests.
const requestsInFlight = {};
const privateTeardownRefs = [];

const testFirefoxPrivate = () => {
  return new Promise(resolve => {
    try {
      const testdb = indexedDB.open('test-idb');
      testdb.onsuccess = () => resolve(true);
      testdb.onerror = () => resolve(false);
    } catch (error) {
      resolve(false);
    }
  });
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isSafari13 = isSafari && navigator.userAgent.split('Version/')[1]?.split('.')[0] === '13';
const isFirefox = 'MozAppearance' in document.documentElement.style;

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const canCacheInIDB = async () => {
  let idbWorks = window.indexedDB && !(isSafari13 && inIframe());
  if (isFirefox && idbWorks) idbWorks = await testFirefoxPrivate();
  return idbWorks;
};

// Listeners that deal with private user info only run when a user is logged in
function dealWithUserChange(e, config) {
  if (e.detail === null) teardownPrivateListeners();
  else setupPrivateListeners(config);
}

function setupPrivateListeners(config) {
  if (privateTeardownRefs.length > 0) teardownPrivateListeners();
  privateTeardownRefs.push(
    setupFundsListener({ baseUrl: config?.accounts?.baseUrl, requestsInFlight }),
    setupAccountOrdersListener({ baseUrl: config?.accounts?.baseUrl, requestsInFlight }),
    setupOpenOrdersByMarketListener({ baseUrl: config?.accounts?.baseUrl, requestsInFlight }),
    setupReferralReportListener({ baseUrl: config?.accounts?.baseUrl, requestsInFlight }),
    setupPreferencesListener({ baseUrl: config?.users?.baseUrl, requestsInFlight }),
    setupDepositsListener({ baseUrl: config?.payments.baseUrl, requestsInFlight }),
    setupWithdrawalsListener({ baseUrl: config?.withdrawals.baseUrl, requestsInFlight })
  );
  // As we fold more private data fetchers in, add them here like above
}

function teardownPrivateListeners() {
  while (privateTeardownRefs.length > 0) {
    const teardown = privateTeardownRefs.pop();
    teardown();
  }
}

export default async function setupFetchListeners(config) {
  window.CAN_CACHE_IDB = await canCacheInIDB();
  const user = await NBXStorage.getItem('user');

  setupMarketsListener({ requestsInFlight });
  setupAssetsListener({ requestsInFlight });
  setupOrderBookListener({ baseUrl: config?.markets?.baseUrl, requestsInFlight });
  setupTradesListener({ baseUrl: config?.markets?.baseUrl, requestsInFlight });
  setupTickersListener({ baseUrl: config?.markets?.baseUrl, requestsInFlight });
  if (user) setupPrivateListeners(config);
  // Storage check runs on page load and also evert 15 mins if page is left open
  // probably far more often than it actually needs to run
  if (window.CAN_CACHE_IDB) {
    window.requestIdleCallback(purgeCache);
    setInterval(() => {
      window.requestIdleCallback(purgeCache);
    }, oneMonthAsMS / 2880);
  }
  window.addEventListener('auth_userchanged', e => dealWithUserChange(e, config)); // TODO: create new 'login' and 'logout' events in new pubsub system
}
