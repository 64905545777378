import { NBXStorage } from '../storage';
import { Session } from '../types';
// eslint-disable-next-line import/no-cycle
import { refreshAccountToken } from './accountToken';
import { getSession, SessionTokenError } from './session';
import { refreshUserToken } from './userToken';

export class AccountTokenError extends Error {
  constructor(message = 'Could not find account token') {
    super(message);
    Object.setPrototypeOf(this, AccountTokenError.prototype);
    this.name = 'AccountTokenError';
  }
}

export interface Context {
  token: string;
  id?: string;
  refresh?: () => Promise<Context>;
}

export interface ContextContainer {
  user: () => Promise<Context>;
  account: () => Promise<Context>;
  session: (refreshSession: boolean) => Promise<Session | null>;
}

const getUserContext = async (): Promise<Context> => {
  try {
    const session = await getSession();
    const token = await NBXStorage.getItem(session.userId);
    if (token) return createUserContext(session, token);
    const newToken = await refreshUserToken(session);
    return createUserContext(session, newToken);
  } catch (e) {
    if (e instanceof SessionTokenError) throw e;
    if (e instanceof Error) throw new Error(`Cannot getUserContext: ${e.message}`);
    else throw e;
  }
};

const createUserContext = (session: Session, token: string): Context => {
  return {
    token,
    id: session.userId,
    refresh: async (): Promise<Context> => {
      const session = await getSession();
      const newToken = await refreshUserToken(session);
      return createUserContext(session, newToken);
    }
  };
};

const getAccountContext = async (): Promise<Context> => {
  try {
    const session = await getSession();
    const accountId = await NBXStorage.getItem('active-account');
    if (!accountId) {
      throw new AccountTokenError('Failed to refresh account token, no active-account defined');
    }
    const token = await NBXStorage.getItem(accountId);
    if (token) {
      return createAccountContext(session, accountId, token);
    }
    const newToken = await refreshAccountToken(session, accountId);
    return createAccountContext(session, accountId, newToken);
  } catch (e) {
    if (e instanceof SessionTokenError) throw e;
    if (e instanceof Error) throw new Error(`Cannot getAccountContext: ${e.message}`);
    else throw e;
  }
};

const createAccountContext = (session: Session, accountId: string, token: string): Context => {
  return {
    token,
    id: accountId,
    refresh: async (): Promise<Context> => {
      const session = await getSession();
      const newToken = await refreshAccountToken(session, accountId);
      return createAccountContext(session, accountId, newToken);
    }
  };
};

export const context: ContextContainer = {
  user: getUserContext,
  account: getAccountContext,
  session: refreshSessionWhenGetting => getSession(refreshSessionWhenGetting)
};
