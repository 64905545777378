import { KeysResult, Preferences as Storage } from '@capacitor/preferences';
import { SecureStoragePlugin } from '@nbx/capacitor-secure-storage-plugin';

// eslint-disable-next-line no-console
const log = (...args: unknown[]): void => console.log('[helpers.storage]', ...args);

const isWeb = (): boolean => window.PLATFORM === 'web' || window.PLATFORM === undefined;

export const NBXStorage = {
  getItemSecure: async (key: string, { useLocalStorage = false } = {}): Promise<string | null> => {
    if (isWeb()) {
      if (useLocalStorage) return localStorage.getItem(key);
      return sessionStorage.getItem(key);
    }
    log('getItemSecure: reading value from SecureStoragePlugin, key =', key);
    const result = await SecureStoragePlugin.get({ key });
    log(
      'getItemSecure: reading value from SecureStoragePlugin - done, "result" is',
      result && 'not empty',
      '"result.value" is',
      result?.value && 'not empty'
    );
    return result?.value ?? null;
  },

  hasItemSecure: async (key: string, { useLocalStorage = false } = {}): Promise<boolean> => {
    if (isWeb()) {
      if (useLocalStorage) return key in localStorage;
      return key in sessionStorage;
    }
    return SecureStoragePlugin.has({ key }).then(result => result?.value);
  },

  setItemSecure: async (key: string, value: any, { useLocalStorage = false } = {}): Promise<boolean> => {
    if (isWeb()) {
      return new Promise(resolve => {
        if (useLocalStorage) localStorage.setItem(key, value);
        else sessionStorage.setItem(key, value);
        resolve(true);
      });
    }
    const stringifiedValue = typeof value !== 'string' ? JSON.stringify(value) : value;
    const result = await SecureStoragePlugin.set({ key, value: stringifiedValue });
    return result.value;
  },

  removeItemSecure: async (key: string, { useLocalStorage = false } = {}): Promise<boolean> => {
    if (isWeb()) {
      return new Promise(resolve => {
        if (useLocalStorage) localStorage.removeItem(key);
        else sessionStorage.removeItem(key);
        resolve(true);
      });
    }
    const result = await SecureStoragePlugin.remove({ key });
    return result.value;
  },

  getItem: async (key: string, { useLocalStorage = false } = {}): Promise<string | null> => {
    if (isWeb()) {
      return new Promise(resolve => {
        if (useLocalStorage) resolve(localStorage.getItem(key));
        else resolve(sessionStorage.getItem(key));
      });
    }
    const result = await Storage.get({ key });
    return result?.value ?? null;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem: async (key: string, value: any, { useLocalStorage = false } = {}): Promise<void> => {
    if (isWeb()) {
      if (useLocalStorage) localStorage.setItem(key, value);
      else sessionStorage.setItem(key, value);
    } else {
      const stringifiedValue = typeof value !== 'string' ? JSON.stringify(value) : value;
      await Storage.set({ key, value: stringifiedValue });
    }
  },

  removeItem: (key: string, { useLocalStorage = false } = {}): void => {
    if (isWeb()) {
      if (useLocalStorage) localStorage.removeItem(key);
      else sessionStorage.removeItem(key);
    } else {
      Storage.remove({ key });
    }
  },

  clear: ({ useLocalStorage = false } = {}): void => {
    if (isWeb()) {
      if (useLocalStorage) localStorage.clear();
      else sessionStorage.clear();
    } else {
      Storage.clear();
    }
  },

  keys: (): Promise<KeysResult> => {
    if (isWeb()) {
      console.warn('NBXStorage.keys() is not supported on the web, will return empty array');
      return new Promise(resolve => {
        resolve({ keys: [] });
      });
    }
    return Storage.keys();
  }
};
