import { getPaged, getPagedByUrl } from '@nbx/frontend-helpers/fetch-api';
import { SessionTokenError, context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

export async function fetchTrades(baseUrl, { marketId, pagingState, nextPageUrl }) {
  let account;
  try {
    account = await context.account();
  } catch (error) {
    if (!(error instanceof SessionTokenError)) throw error;
  }
  if (nextPageUrl) {
    return getPagedByUrl(nextPageUrl, account);
  }
  return getPaged(`${baseUrl}/${marketId}/trades`, account, pagingState);
}

export function generateFetchKey({ marketId, pagingState, nextPageUrl } = {}) {
  if (marketId && nextPageUrl) {
    const urlPagingState = nextPageUrl.split('=')[1];
    return `trades-${marketId}-${urlPagingState.split('.').slice(0, 2).join('.')}`;
  }
  if (marketId && pagingState) return `trades-${marketId}-${pagingState.split('.').slice(0, 2).join('.')}`;
  if (marketId) return `trades-${marketId}`;
  return `trades`;
}

function setupTradesListener({ baseUrl, requestsInFlight }) {
  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.marketId) return false;
    return true;
  }

  return setupFetchListener({
    fetchContent: params => fetchTrades(baseUrl, params),
    isMessageValid,
    generateFetchKey,
    requestsInFlight
  });
}

export default setupTradesListener;
