import languages from 'constants/languages';

const loadPath = `/i18n/{{lng}}/{{lng}}.json`;
window.i18n_PATH = loadPath;
window.i18n_SETTINGS = {
  fallbackLng: 'en',
  lowerCaseLng: true,
  whitelist: Object.keys(languages),
  backend: { loadPath },
  detection: {
    order: ['localStorage', 'navigator', 'path', 'querystring'],
    lookupFromPathIndex: 0,
    caches: ['localStorage']
  }
};
