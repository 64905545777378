import { context } from '@nbx/frontend-helpers/auth';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { createLogger } from 'helpers/Logger';

let baseUrl = 'messaging';

const log = createLogger('[messaging]');

const NBXMessaging = {
  init: config => ({ baseUrl } = config),
  setupPermissions: async () => {
    const check = await FirebaseMessaging.checkPermissions();
    if (check.receive === 'prompt') {
      log.info('No permissions. Asking the user');
      const request = await FirebaseMessaging.requestPermissions();
      log.info(`Request receive: ${request.receive}`);
      return request.receive === 'granted';
    }

    return true;
  },

  setup: async () => {
    if (window.PLATFORM === 'web') {
      return;
    }

    const hasPermissions = await NBXMessaging.setupPermissions();

    let accountToken;
    try {
      const account = await context.account();
      accountToken = account.token;
    } catch {
      log.info('User is not logged in. Skipping token registration');
      return;
    }

    const { token: firebaseToken } = await FirebaseMessaging.getToken();
    log.info(`Firebase token is ${firebaseToken}`);

    if (hasPermissions) {
      log.info('User gave permissions');
      await NBXMessaging.registerToken(accountToken, firebaseToken);
    } else {
      log.info("Skipped sending the token: user didn't give permissions");
    }
  },

  registerToken: async (accountToken, firebaseToken) => {
    const response = await fetch(`${baseUrl}/tokens`, {
      method: 'POST',
      body: JSON.stringify({ firebaseToken }),
      headers: { authorization: `bearer ${accountToken}` }
    });

    if (response.ok) {
      log.info(`Firebase token registered: response_status=${response.status}`);
    } else {
      // Fail silently for now
      log.info(`Failed to register firebase token. response_status=${response.status}`);
    }
  }
};

export default NBXMessaging;
