import dayjs from 'dayjs-ext';
import Big from 'big.js';
import isBetween from 'dayjs-ext/plugin/isBetween';
import { publicStore } from './stores';
import { setupFetchListener, fetchDataOutsideListener } from './fetchListener';
import { generateFetchKey as generateTradesFetchKey, fetchTrades } from './trades';
import { generateFetchKey as generateMarketsFetchKey, fetchMarkets } from './markets';

Big.RM = 0;
dayjs.extend(isBetween);

function setupTickersListener({ baseUrl, requestsInFlight }) {
  async function fetchTickers({ marketId }, timeInterval) {
    const markets = await fetchDataOutsideListener({
      params: {},
      generateFetchKey: generateMarketsFetchKey,
      fetchContent: fetchMarkets,
      store: publicStore,
      requestsInFlight,
      timeInterval
    });
    // Fetch data for 1 market if marketId is specified
    if (marketId) {
      const [marketData] = markets.filter(market => market.id === marketId);
      const tickerData = await getMarketTickers(marketData.id, timeInterval);
      return {
        ...tickerData,
        ...marketData
      };
      // Fetch data for every market if none is specified
    }
    const tickerPromises = markets.map(async marketData => getMarketTickers(marketData.id, timeInterval));
    const tickerData = await Promise.allSettled(tickerPromises);
    const results = tickerData.map((result, index) => {
      const { value, status } = result;
      if (status === 'rejected') {
        return {
          error: true,
          data: markets[index]
        };
      }
      return {
        error: false,
        data: {
          ...value,
          ...markets[index]
        }
      };
    });
    return results;
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    return true;
  }

  function generateFetchKey({ marketId } = {}) {
    if (marketId) return `tickers-${marketId}`;
    return `tickers`;
  }

  const sortByCreatedAtAsc = (a, b) => {
    const dateA = dayjs(a.createdAt);
    const dateB = dayjs(b.createdAt);
    if (dateA > dateB) return 1;
    if (dateB > dateA) return -1;
    return 0;
  };

  async function getMarketTickers(marketId, timeInterval) {
    const freq = 'D';
    const start = dayjs(new Date()).subtract(1, 'day').toISOString();
    const end = dayjs(new Date());
    const [candlesticks, trades] = await fetchCandlesticks(marketId, freq, start, end, timeInterval);
    let values = null;
    let ticker = null;
    if (candlesticks.length > 0) [values] = candlesticks;
    if (trades.length) [ticker] = trades;

    const getValue = index => {
      if (values) return parseFloat(values[index]);
      return null;
    };

    const stats24h = {
      price: ticker?.price ?? '0',
      volume: getValue(1),
      open: getValue(2),
      high: getValue(3),
      low: getValue(4),
      close: getValue(5)
    };

    const changeDiff = stats24h.close - stats24h.open;
    stats24h.changeAmount = changeDiff || 0;
    stats24h.isChangeUp = changeDiff > 0;
    if (changeDiff === 0) stats24h.isChangeUp = null;
    stats24h.changeNumber = changeDiff ? (changeDiff / stats24h.open) * 100 : 0;
    stats24h.change = `${stats24h.changeNumber.toFixed(2)}%`;
    return stats24h;
  }

  async function fetchCandlesticks(marketId, freq, start, end, timeInterval) {
    let filledDateRange = false;
    let nextPageUrl = null;
    let currentEnd = end;
    let trades = [];
    let processedCandlesticks = [];

    try {
      /* eslint-disable no-await-in-loop, prefer-destructuring */
      while (!filledDateRange) {
        const response = await fetchDataOutsideListener({
          params: { marketId, nextPageUrl },
          generateFetchKey: generateTradesFetchKey,
          fetchContent: params => fetchTrades(baseUrl, params),
          store: publicStore,
          requestsInFlight,
          timeInterval
        });
        nextPageUrl = response.nextPageUrl;
        trades = [...trades, ...response.body];

        const lastTradeIsBeforeStart = dayjs(response.body[response.body.length - 1]?.createdAt).isBefore(
          dayjs(start)
        );
        const lastTradeIsNotAfterCurrentEnd = !dayjs(
          response.body[response.body.length - 1]?.createdAt
        ).isAfter(dayjs(currentEnd));
        if (!nextPageUrl || lastTradeIsBeforeStart) {
          // Break the loop if the page is empty
          // If last trade is before is the start of the date range we're trying to fill
          // Or there is more data for this market
          // Then we can process the data we have and then break the loop
          processedCandlesticks = processCandlesticks(trades, freq, start, end);
          filledDateRange = true;
        } else if (lastTradeIsNotAfterCurrentEnd) {
          // Else we process the data we have, and fetch another page
          currentEnd = dayjs(trades[0].createdAt);
        }
      }
    } catch (error) {
      // TODO: handle this better?
      console.error('error fetching trades', error); // eslint-disable-line
    }
    return [processedCandlesticks, trades];
  }

  function processCandlesticks(trades, freq, start, end) {
    // If no trades, return empty array
    if (trades.length === 0) {
      return [];
    }

    // get interval in minutes
    const interval = getIntervalInMinutes(freq);

    // format start and end time to dayjs instances
    const formattedStart = start instanceof dayjs ? start : dayjs(start);
    const formattedEnd = end instanceof dayjs ? end : dayjs(end);

    // filter out dates out of date range
    const tradesInDateRange = trades.filter(trade => {
      return dayjs(trade.createdAt).isAfter(formattedStart) || dayjs(trade.createdAt).isSame(formattedStart);
    });

    // if no trades in date range return the last trade time, volume(not from trade, will just be 0), open, high, low, close
    if (tradesInDateRange.length === 0) {
      const [lastTrade] = trades;
      const time = dayjs(lastTrade.createdAt).unix();
      const volume = 0;
      const open = parseFloat(lastTrade.price);
      const high = parseFloat(lastTrade.price);
      const low = parseFloat(lastTrade.price);
      const close = parseFloat(lastTrade.price);
      return [[time, volume, open, high, low, close]];
    }

    // Add the last trade before the date range, so we can get the proper opening price
    if (trades.length > tradesInDateRange.length) {
      tradesInDateRange.push(trades[tradesInDateRange.length]);
    }

    // get all the possible times for candlesticks at the current interval
    const intervalDates = [formattedStart];
    while (intervalDates[intervalDates.length - 1].isBefore(formattedEnd)) {
      const newIntervalDate = intervalDates[intervalDates.length - 1].add(interval, 'm');
      intervalDates.push(newIntervalDate);
    }

    // get all trades that fall into the candlestick buckets
    const candlesticks = [];
    intervalDates.forEach((intervalDate, i) => {
      if (intervalDates[i + 1] !== undefined) {
        // get trades for each interval
        const intervalTrades = tradesInDateRange.filter(trade =>
          dayjs(trade.createdAt).isBetween(intervalDate, intervalDates[i + 1], null, '[)')
        );
        if (i === 0) {
          intervalTrades.unshift(tradesInDateRange[tradesInDateRange.length - 1]);
        }
        if (intervalTrades.length > 0) {
          // reduce values of trades for time, volume, open, high, low, close
          intervalTrades.sort(sortByCreatedAtAsc);
          const time = intervalDate.unix();
          // ignore first item for volume when dealing with first interval (is the price from before the requested time)
          const volumeTrades = i === 0 ? intervalTrades.slice(1, intervalTrades.length) : intervalTrades;
          let volume;
          try {
            volume = parseFloat(volumeTrades.reduce((total, trade) => total.plus(trade.quantity), Big(0)));
          } catch (err) {
            console.warn(`Could not calculate market volume:`, err);
            volume = null;
          }
          const open = parseFloat(intervalTrades[0].price);
          const high = Math.max(...intervalTrades.map(trade => trade.price));
          const low = Math.min(...intervalTrades.map(trade => trade.price));
          const close = parseFloat(intervalTrades[intervalTrades.length - 1].price);
          candlesticks.push([time, volume, open, high, low, close]);
        }
      }
    });
    return candlesticks;
  }

  return setupFetchListener({
    fetchContent: fetchTickers,
    isMessageValid,
    generateFetchKey,
    requestsInFlight
  });
}

export function getIntervalInMinutes(interval) {
  switch (interval) {
    case 'D':
      return 1440;
    case '6H':
      return 360;
    case 'H':
      return 60;
    case '15M':
      return 15;
    case '5M':
      return 5;
    case 'Min':
      return 1;
    default:
      return 1440;
  }
}

export default setupTickersListener;
