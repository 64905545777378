import { get } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function setupReferralReportListener({ baseUrl, requestsInFlight }) {
  async function fetchReferralReport({ monthsCount, accountId }) {
    const account = await context.account();
    if (account.id !== accountId) return null;
    return get(
      `${baseUrl}/${accountId}/referral_report${monthsCount ? `?months_count=${monthsCount}` : ''}`,
      account
    );
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.accountId) return false;
    return true;
  }

  function generateFetchKey({ accountId, monthsCount } = {}) {
    if (accountId && monthsCount) return `referralReport-${monthsCount}-${accountId}`;
    if (accountId) return `referralReport-${accountId}`;
    return `referralReport`;
  }

  return setupFetchListener({
    fetchContent: fetchReferralReport,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupReferralReportListener;
