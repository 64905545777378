import getDeferredPromise from './getDeferredPromise';

class ReqRateLimiter {
  #REQUESTS_PER_MIN = 700; // 1000 requests per minute is the limit, we want to leave some gap for other client's requests

  #requestsLeft = this.#REQUESTS_PER_MIN;

  #requestsQueue = [];

  constructor() {
    setInterval(() => {
      this.#rateLimitReset();
    }, 60000);
  }

  #redoQueue = () => {
    while (this.#requestsQueue.length > 0 && this.#requestsLeft > 0) {
      const req = this.#requestsQueue.shift();
      this.#executeRequest(req);
    }
  };

  #rateLimitReset = () => {
    this.#requestsLeft = this.#REQUESTS_PER_MIN;
    this.#redoQueue();
  };

  #executeRequest = req => {
    // eslint-disable-next-line operator-assignment
    this.#requestsLeft = this.#requestsLeft - 1;
    return req();
  };

  rateLimitedRequest = req => {
    if (this.#requestsLeft > 0) {
      return this.#executeRequest(req);
    }

    const deferred = getDeferredPromise();
    const wrappedRequest = async () => {
      const res = await req();
      deferred.resolve(res);
    };
    this.#requestsQueue.push(wrappedRequest);
    return deferred.promise;
  };
}

export default new ReqRateLimiter();
