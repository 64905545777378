import { get, getPaged, getPagedByUrl } from '@nbx/frontend-helpers/fetch-api';
import { context } from '@nbx/frontend-helpers/auth';
import { setupFetchListener } from './fetchListener';

function formatBalance(balance) {
  balance.asset = balance.id;
  return balance;
}

function setupFundsListener({ baseUrl, requestsInFlight }) {
  async function fetchFunds({ asset, accountId, nextPageUrl }) {
    const account = await context.account();
    if (account.id !== accountId || (nextPageUrl && !nextPageUrl.includes(account.id))) return null;
    if (asset)
      return formatBalance(
        await get(`${baseUrl}/${accountId}/assets/${asset}?include_freezes=false`, account)
      );
    let response;
    if (nextPageUrl) response = await getPagedByUrl(nextPageUrl, account);
    else response = await getPaged(`${baseUrl}/${accountId}/assets?include_freezes=false`, account);
    response.body = response.body.map(balance => formatBalance(balance));
    return response;
  }

  function isMessageValid(message) {
    if (!message?.params) return false;
    if (!message.params?.accountId) return false;
    return true;
  }

  function generateFetchKey({ asset, accountId, nextPageUrl } = {}) {
    if (asset && accountId) return `funds-${asset}-${accountId}`;
    if (accountId && nextPageUrl) return `funds-${accountId}-${nextPageUrl}`;
    if (accountId) return `funds-${accountId}`;
    return `funds`;
  }

  return setupFetchListener({
    fetchContent: fetchFunds,
    isMessageValid,
    generateFetchKey,
    requestsInFlight,
    isPrivate: true
  });
}

export default setupFundsListener;
