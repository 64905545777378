import { fetchAndFormatMarkets } from 'helpers/markets';
import { setupFetchListener } from './fetchListener';

export async function fetchMarkets({ disabled = false } = {}) {
  return fetchAndFormatMarkets({ disabled });
}

export const generateFetchKey = ({ disabled = false } = {}) => `markets${disabled ? '-all' : ''}`;

function setupMarketsListener({ requestsInFlight }) {
  const isMessageValid = () => true;

  return setupFetchListener({
    fetchContent: fetchMarkets,
    isMessageValid,
    generateFetchKey,
    requestsInFlight
  });
}

export default setupMarketsListener;
