import { useEffect, useState } from 'react';
import { NBXStorage } from '../storage';
import { publishEvent } from '../publish';

const STORAGE_KEY_DEVICE_TOKEN = 'device-token';
const STORAGE_KEY_LAST_BIO_AUTHENTICATED = 'last-bio-authenticated';
const LAST_BIO_AUTHENTICATED_CHANGED = 'last-bio-authenticated-changed';

const parseJSONOrNull = (storedValue: string | null): any | null => {
  if (storedValue === null) return null;
  return JSON.parse(storedValue);
};

export const setLastBioAuthenticatedEpochMs = () =>
  NBXStorage.setItem(STORAGE_KEY_LAST_BIO_AUTHENTICATED, Date.now(), { useLocalStorage: true }).then(value =>
    publishEvent(LAST_BIO_AUTHENTICATED_CHANGED, value)
  );

export const getLastBioAuthenticatedEpochMs = () =>
  NBXStorage.getItem(STORAGE_KEY_LAST_BIO_AUTHENTICATED, { useLocalStorage: true });

export function useLastBioAuthenticatedEpochMs(): number | null | undefined {
  const [value, setValue] = useState<number | null | undefined>(undefined);

  useEffect(() => {
    async function init(): Promise<void> {
      const initialVal = await getLastBioAuthenticatedEpochMs();
      // console.log('useLastBioAuthenticatedEpochMs: init last-bio-authenticated-changed', initialVal);
      setValue(initialVal ? +initialVal : null);
    }

    function valueChanged(e): void {
      setValue(e.detail ? +e.detail : null);
    }

    init();
    window.addEventListener(LAST_BIO_AUTHENTICATED_CHANGED, valueChanged);
    return (): void => window.removeEventListener(LAST_BIO_AUTHENTICATED_CHANGED, valueChanged);
  }, []);

  return value;
}

let readDeviceTokenPromise: Promise<string> | null;

export const clearGlobalReadDeviceTokenPromise = (): void => {
  readDeviceTokenPromise = null;
};

export const readDeviceToken = (
  { resetAuthDate = true, parsed = false }: { resetAuthDate?: boolean; parsed?: boolean } = {
    resetAuthDate: true,
    parsed: false
  }
): Promise<string> => {
  if (readDeviceTokenPromise) return readDeviceTokenPromise;
  readDeviceTokenPromise = doReadDeviceToken({ resetAuthDate, parsed });
  return readDeviceTokenPromise;
};

export const doReadDeviceToken = (
  { resetAuthDate = true, parsed = false }: { resetAuthDate?: boolean; parsed?: boolean } = {
    resetAuthDate: true,
    parsed: false
  }
): Promise<string> =>
  NBXStorage.getItemSecure(STORAGE_KEY_DEVICE_TOKEN)
    .then(deviceTokenJSON => {
      console.log(
        `[helpers.readDeviceToken] (resetAuthDate: ${resetAuthDate}) token exists: ${!!deviceTokenJSON}`
      );
      if (deviceTokenJSON && resetAuthDate) setLastBioAuthenticatedEpochMs();
      console.log(`[helpers.readDeviceToken] retrun (parsed: ${parsed})`);
      return parsed ? parseJSONOrNull(deviceTokenJSON).token : deviceTokenJSON;
    })
    .finally(clearGlobalReadDeviceTokenPromise);

export const writeDeviceToken = async ({
  deviceTokenResponse,
  resetAuthDate = true
}: {
  deviceTokenResponse: string;
  resetAuthDate?: boolean;
}) => {
  console.log(`[helpers.writeDeviceToken] set item secure deviceTokenResponse ${!!deviceTokenResponse}`);
  const isSuccessful = await NBXStorage.setItemSecure(STORAGE_KEY_DEVICE_TOKEN, deviceTokenResponse);
  if (isSuccessful && resetAuthDate) setLastBioAuthenticatedEpochMs();
  return isSuccessful;
};

export const isDeviceTokenExists = () => NBXStorage.hasItemSecure(STORAGE_KEY_DEVICE_TOKEN);

export const removeDeviceToken = () => NBXStorage.removeItemSecure(STORAGE_KEY_DEVICE_TOKEN);
