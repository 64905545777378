const handlers = {};

const setup = () => {
  window.SUBSCRIBE = (topic, handler) => {
    if (typeof handler !== 'function') return;
    const topicHandlers = handlers[topic] || [];
    topicHandlers.push(handler);
    handlers[topic] = topicHandlers;
  };

  window.UNSUBSCRIBE = (topic, handler) => {
    const topicHandlers = handlers[topic] || [];
    const index = topicHandlers.indexOf(handler);
    if (index >= 0) {
      topicHandlers.splice(index, 1);
      handlers[topic] = topicHandlers;
    }
  };

  window.PUBLISH = (topic, message) => {
    const topicHandlers = handlers[topic] || [];
    // Need to clone the array here to handle unsubscriptions happening while mid-publish
    // they shift the array while iterating, leading to some subscribers never receiving
    // Consider: hash map of subscriptions with random IDs?
    const clonedTopicHandlers = [...topicHandlers];
    clonedTopicHandlers.forEach(handler => {
      handler(message);
    });
  };
};

export default setup;
