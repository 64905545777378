/* eslint-disable no-console */

export const createLogger = prefix => {
  const log = console;
  const logger = {
    info: (message, context) => log.info(`${prefix} ${message}`, context ? JSON.stringify(context) : ''),
    warn: (message, context) => log.warn(`${prefix} ${message}`, context),
    error: (message, context) => log.error(`${prefix} ${message}`, context),
    addAction: (actionName, context) => {
      if (context) log.info(`${prefix} ${actionName}`, context);
      else log.info(`${prefix} ${actionName}`);
    }
  };

  return logger;
};

export const shrinkURL = s => (s?.length > 60 ? `${s.slice(0, 50)}...${s.slice(-7)}` : s);
