import { fetchAndFormatAssets } from 'helpers/markets';
import { setupFetchListener } from './fetchListener';

export async function fetchAssets() {
  return fetchAndFormatAssets();
}

export const generateFetchKey = () => 'assets';

function setupAssetsListener({ requestsInFlight }) {
  const isMessageValid = () => true;

  return setupFetchListener({
    fetchContent: fetchAssets,
    isMessageValid,
    generateFetchKey,
    requestsInFlight
  });
}

export default setupAssetsListener;
