import { privateStore, publicStore } from './stores';

async function purgeCacheByQuota() {
  const { quota, usage, usageDetails } = await navigator.storage.estimate();
  const indexedDBUsage = usageDetails?.indexedDB ?? usage;
  // If using 10% of quota, or more than 50MB, do a purge
  if (indexedDBUsage / quota > 0.1 || indexedDBUsage / 1000000 > 50) {
    publicStore.clear();
    privateStore.clear();
  }
}

async function purgeCacheByNumKeys() {
  const publicKeys = await publicStore.keys();
  const privateKeys = await privateStore.keys();
  const numKeysInIDB = publicKeys.length + privateKeys.length;
  // If using more than 770 keys, do a purge
  // for an average 65KB request (full page of 1000 trades), 770 rows is roughly 50MB
  if (numKeysInIDB > 770) {
    publicStore.clear();
    privateStore.clear();
  }
}

export const oneMonthAsMS = 1000 * 60 * 60 * 24 * 30;
async function purgeCache() {
  try {
    const timeAtStart = new Date().getTime();
    if (navigator.storage && typeof navigator.storage?.estimate === 'function')
      await purgeCacheByQuota(oneMonthAsMS, timeAtStart);
    else await purgeCacheByNumKeys(oneMonthAsMS, timeAtStart);
  } catch (caughtError) {
    console.error('Failed to purge cache of old data:', caughtError);
  }
}

export default purgeCache;
