import { NBXStorage } from '@nbx/frontend-helpers/storage';

let previewFeature;

const setPublicPath = () => {
  __webpack_public_path__ = `${window.FEATURE_PREVIEW_URL}/static/`; // eslint-disable-line
};

const detectFeaturePreview = async () => {
  if (window.FEATURE_PREVIEW) {
    setPublicPath();
    return false;
  }
  previewFeature = await NBXStorage.getItem('preview_feature');
  if (previewFeature && typeof previewFeature === 'string') {
    window.FEATURE_PREVIEW = true;
    return true;
  }
  return false;
};

export const loadFeatureBranch = () => {
  const dest = document.getElementsByTagName('html')[0];
  const baseFeatureUrl = `https://${previewFeature}-frontend-wrapper.dev.nbx.com`;
  window.FEATURE_PREVIEW_URL = baseFeatureUrl;
  fetch(baseFeatureUrl)
    .then(data => data.text())
    .then(html => {
      const urlIndex = html.indexOf('/static/wrapper.');
      const scriptURL = html.slice(urlIndex, urlIndex + 39);
      const element = document.createElement('script');
      element.src = `${baseFeatureUrl}${scriptURL}`;
      element.type = 'text/javascript';
      element.async = true;
      dest.innerHTML = html;
      document.head.appendChild(element);
    });
};

export default detectFeaturePreview;
